/* CSS */

html {
  --gradient-color-1: #ecc80c;
  --gradient-1-2: #ecc608, #edbb00, #ed9b00, #e76000, #df340b, #dc1a13;
  --gradient-color-2: #db1414;
  --gradient-2-3: #dc081b, #de003b, #d5007a, #9e00d2, #5a1fe8, #2342e7;
  --gradient-color-3: #1046e6;
  --gradient-3-1: #0065fc, #008fe2, #00b5c6, #22cc8a, #b6cf25, #e5c902;
  --background-gradient: linear-gradient(
    to bottom right,
    var(--gradient-color-1),
    var(--gradient-1-2),
    var(--gradient-color-2),
    var(--gradient-2-3),
    var(--gradient-color-3),
    var(--gradient-3-1),
    var(--gradient-color-1),
    var(--gradient-1-2),
    var(--gradient-color-2)
  );
  --gradient-animation: moveGradient 1s ease-in-out;
  --gradient-animation-position-start: 0% 0%;
  --gradient-animation-position-end: 0% 0%;
  --gradient-size: 400% 400%;
  --color-offwhite: #fafafa;
  --color-offblack: #222;
  --color-darkgrey: #555;
  --color-lightgrey: #ddd;
}

/* Animation */

@keyframes moveGradient {
  0% {
    background-position: var(--gradient-animation-position-start);
  }
  100% {
    background-position: var(--gradient-animation-position-end);
  }
}

.animate,
.contact-line p.animate::after {
  animation: var(--gradient-animation);
}

.gradient-background,
.contact-line p.gradient-background::after {
  background-position: var(--gradient-animation-position-end);
  background-image: var(--background-gradient);
  background-size: var(--gradient-size);
  background-attachment: fixed;
}

/* General */

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-offwhite);
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: 'Poppins', sans-serif;
}

#section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 110px;
  padding: 0 0 25px 0;
}

/* Header */

header {
  height: 100vh;
  min-height: 800px;
  width: 1300px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#name-container {
  margin: 0 50px 0 0;
}

h1 {
  font-size: 4rem;
  font-weight: 700;
  margin: 0;
  color: var(--color-offblack);
}

#name-container > h1 span {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

#rotating-title-container {
  background-color: var(--color-offwhite);
  position: relative;
  height: 6rem;
  margin: 0;
  display: inline-block;
  overflow: hidden;
}

#rotating-title-container::before,
#rotating-title-container::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 0.75rem;
  z-index: 1;
}

#rotating-title-container::before {
  top: 0;
  background: linear-gradient(to top, transparent, var(--color-offwhite));
}

#rotating-title-container::after {
  bottom: 0;
  background: linear-gradient(to bottom, transparent, var(--color-offwhite));
}

#rotating-title-container h1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

header img {
  width: 50%;
  margin: 0;
  padding: 0;
  border-radius: 100%;
}

/* Sections */

section {
  width: 1300px;
  gap: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

section h2 {
  text-align: center;
  color: var(--color-offblack);
  font-size: 2.5rem;
  margin: 0;
}

/* Gradient Button */

.gradient-button {
  position: relative;
  padding: 8px;
  border-radius: 5px;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  transition: 0.3s;
}

.gradient-button::after {
  background-color: transparent;
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 2px;
  transition: 0.3s;
}

.gradient-button span {
  position: relative;
  display: flex;
  padding: 20px 50px;
  color: var(--color-offwhite);
  background-clip: text;
  -webkit-background-clip: text;
  z-index: 1;
  transition: 0.3s;
}

@media (hover: hover) {
  .gradient-button:hover::after {
    background-color: var(--color-offwhite);
    margin: 8px;
  }

  .gradient-button:hover span {
    color: transparent;
  }
}

/* Skills */

.skill-section div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: 40px 60px;
}

.skill-section div h3 {
  font-size: 3.25rem;
  margin: 0;
  padding: 1rem 1.5rem;
  border-radius: 3rem;
  color: var(--color-offwhite);
}

/* Side By Side Sections */

.sideBySideSectionContainer {
  display: flex;
  flex-direction: row;
  align-items: start;
}

/* Contact */

.contact-column {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.contact-information {
  display: flex;
  flex-direction: row;
  gap: 100px;
}

.contact-line {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
}

.contact-line {
  font-size: 1.5rem;
  font-weight: 600;
}

.contact-line p {
  position: relative;
  margin: 0;
  line-height: 2.5rem;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
}

.contact-line svg {
  width: 3.75rem;
  height: 3.75rem;
  fill: var(--color-offwhite);
  margin: 0;
  border: none;
  shape-rendering: geometricPrecision;
}

.contact-line p::after {
  content: '';
  position: absolute;
  height: 3px;
  left: 0;
  bottom: 0;
  width: 0;
  transition: width 0.75s;
}

@media (hover: hover) {
  .contact-line:hover > p::after {
    width: 100%;
  }
}

/* Color Changing Section */

.color-change-section {
  height: 75vh;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.color-change-section::after {
  background-color: var(--color-offwhite);
  border-radius: 5px;
  height: calc(75vh - 100px);
  width: 1200px;
  position: absolute;
  content: '';
}

.color-change-section > * {
  z-index: 1;
}

.color-change-button-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.color-change-button {
  border-radius: 100%;
  border: none;
  width: 75px;
  height: 75px;
  outline: 2px solid transparent;
  outline-offset: -10px;
  transition:
    outline 0.3s,
    outline-offset 0.3s;
}

.color-change-button-active {
  outline: 5px solid var(--color-offwhite);
  outline-offset: -15px;
}

.color-change-button:disabled {
  /* opacity: 0.5; */
}

.color-change-button:hover {
  cursor: pointer;
  outline: 2px solid var(--color-offwhite);
}

.color-change-button.color-change-button-active:hover {
  cursor: default;
  outline: 5px solid var(--color-offwhite);
}

#color-change-button-0 {
  background-image: linear-gradient(
    to bottom right,
    var(--gradient-color-1),
    var(--gradient-1-2),
    var(--gradient-color-2)
  );
}

#color-change-button-1 {
  background-image: linear-gradient(
    to bottom right,
    var(--gradient-color-2),
    var(--gradient-2-3),
    var(--gradient-color-3)
  );
}

#color-change-button-2 {
  background-image: linear-gradient(
    to bottom right,
    var(--gradient-color-3),
    var(--gradient-3-1),
    var(--gradient-color-1)
  );
}

/* Footer */

footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: calc(100% - 100px);
  padding: 0px 50px;
  margin: 50px 0 0 0;
  background-color: var(--color-lightgrey);
}

footer p {
  text-decoration: none;
  font-weight: 300;
  color: var(--color-darkgrey);
  display: inline;
}

footer p:first-child {
  text-align: left;
}

footer p:last-child {
  text-align: right;
}

/* Smaller Desktop Display */

@media only screen and (max-width: 1500px) {
  header {
    width: 1000px;
    min-height: 650px;
  }

  section {
    width: 1000px;
  }

  .color-change-section::after {
    width: 900px;
  }

  .skill-section div {
    gap: 40px 50px;
  }

  h1 {
    font-size: 3rem;
  }

  .skill-section div h3 {
    font-size: 2.5rem;
  }
}

/* Large Mobile (Tablet) */

@media only screen and (max-width: 1100px) {
  header {
    flex-direction: column;
    width: 600px;
    min-height: 700px;
    height: 90vh;
  }

  section {
    width: 600px;
    max-width: 80vw;
  }

  .color-change-section::after {
    width: 500px;
    max-width: calc(80vw - 100px);
  }

  .sideBySideSectionContainer {
    flex-direction: column;
    gap: 100px;
  }

  .skill-section div {
    gap: 30px;
  }

  #name-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 50px 0;
  }

  h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  #rotating-title-container {
    height: 4rem;
  }

  header img {
    width: 75%;
  }

  .skill-section div h3 {
    font-size: 2rem;
  }

  h2 {
    font-size: 2rem;
  }

  .contact-information {
    flex-direction: column;
    gap: 0.75rem;
  }

  footer {
    padding: 0px 25px;
    width: calc(100% - 50px);
  }

  footer p {
    font-size: 0.75rem;
  }
}

/* Mobile */

@media only screen and (max-width: 600px) {
  header {
    width: 100vw;
  }

  h2 {
    width: 80%;
  }

  .contact-line {
    font-size: 1.15rem;
  }
}

/* Small Mobile */

@media only screen and (max-width: 400px) {
  body {
    min-width: 320px;
  }

  header {
    min-height: 550px;
    min-width: 320px;
  }

  section {
    width: 310px;
  }

  .color-change-section::after {
    width: 210px;
  }

  #cardContainer {
    margin: 0;
  }

  h1 {
    font-size: 2rem;
  }

  .skill-section div {
    gap: 20px;
  }

  .skill-section div h3 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  .card h3 {
    font-size: 1.5rem;
  }
}
